// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-commodities-js": () => import("./../src/pages/services/commodities.js" /* webpackChunkName: "component---src-pages-services-commodities-js" */),
  "component---src-pages-services-drying-and-storing-js": () => import("./../src/pages/services/drying-and-storing.js" /* webpackChunkName: "component---src-pages-services-drying-and-storing-js" */),
  "component---src-pages-services-fertilizers-js": () => import("./../src/pages/services/fertilizers.js" /* webpackChunkName: "component---src-pages-services-fertilizers-js" */),
  "component---src-pages-services-foreign-trade-js": () => import("./../src/pages/services/foreign-trade.js" /* webpackChunkName: "component---src-pages-services-foreign-trade-js" */),
  "component---src-pages-services-real-estate-js": () => import("./../src/pages/services/real-estate.js" /* webpackChunkName: "component---src-pages-services-real-estate-js" */),
  "component---src-pages-services-rice-trading-js": () => import("./../src/pages/services/rice-trading.js" /* webpackChunkName: "component---src-pages-services-rice-trading-js" */),
  "component---src-pages-storaged-js": () => import("./../src/pages/storaged.js" /* webpackChunkName: "component---src-pages-storaged-js" */)
}

