const styles = {
  colors: {
    red: '#DA241C',
    darkRed: '#AE1D16',
    darkGrey: '#464555',
    darkGrey30: 'rgba(70, 69, 85, 0.3)',
    lightGrey: '#ABA9BC',
    lightGrey20: 'rgba(162, 172, 189, 0.2)',
    white: '#FFFFFF',
    white30: 'rgba(255, 255, 255, 0.3)',
    blue: '#20A0F6',
    green: '#008E1F',
    blueTwitter: '#1DA1F2',
    blueFacebook: '#3C5A99'
  }
}

export { styles }
export default styles
