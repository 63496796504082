import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import pt from './languages/pt'
import en from './languages/en'
import es from './languages/es'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { en, pt, es },
    fallbackLng: 'pt',
    debug: process.env.GATSBY_ENVIRONMENT === 'development'
  })

export default i18n
