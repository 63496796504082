import header from './components/header.json'
import footer from './components/footer.json'
import banner from './components/banner.json'
import contactBanner from './components/contactBanner.json'
import table from './components/table.json'
import weather from './components/weather.json'
import graphics from './components/graphics.json'
import brokers from './components/brokers.json'

import commodities from './services/commodities.json'
import fertilizer from './services/fertilizer.json'
import foreignTrade from './services/foreignTrade.json'
import realEstate from './services/realEstate.json'
import riceTrading from './services/riceTrading.json'
import storing from './services/storing.json'
import contact from './services/contact.json'
import error from './services/error.json'

import about from './about.json'
import home from './home.json'
import titlePages from './titlePages.json'

export default {
  header: header,
  footer: footer,
  banner: banner,
  contactBanner: contactBanner,
  weather: weather,
  commodities: commodities,
  fertilizer: fertilizer,
  foreignTrade: foreignTrade,
  realEstate: realEstate,
  riceTrading: riceTrading,
  storing: storing,
  contact: contact,
  about: about,
  home: home,
  titlePages: titlePages,
  error: error,
  table: table,
  graphics: graphics,
  brokers: brokers
}
